let str2 = `
<p>请您做检测前，仔细阅读以下内容:</p>
<h4>一、关于二手烟暴露检测</h4>
<p>1. 二手烟暴露评估检测的是尿液中的可替宁。可替宁是广泛应用于近期烟草产品使用和暴露的评估指标，包括二手烟接触
情况。可替宁是尼古丁的代谢产物，而尼古J是烟草制品和烟草烟雾中发现的活性药物，被动吸烟吸入的烟草烟雾是非吸
烟者接触尼古丁的主要来源。烟草烟雾中传递的尼古丁中多达90%可以被吸入身体中。</p>
<p>2.该检测服务仅用于非医疗用途。</p>
<p>3.在满足下列要求后，本公司方可进行检测:</p>
<p>(1)受检者已按要求填写自助检验单并已签署知情同意书;</p>
<p>(2) 所采集的样本符合要求。</p>
<p>3.收到样本后-般会在7个工作日内完成检测并出具报告,特殊情况可能需要更长时间(如样本不符合要求需要重新采样等)。</p>
<p>4.受检者应对样本真实性(即样本是否属于受检者本人)负责。</p>
<h4>二、隐私声明</h4>
<p>我们承诺对受检者个人信息与检测结果给予严格保护，检测结果和报告仅会通过受检者指定的方式告知。除非得到本人确
认，不会透露给第三方，但受检者知悉并许可我们在隐去受检者姓名等隐私信息后，在法律允许的框架内，可在报告解
读、科研、教学及质量控制中合理使用受检者检测数据。</p>
<h4>三、申请人承诺与声明.</h4>
<p>1. 我已阅读了以上内容，并了解二手烟暴露检测的相关信息;</p>
<p>2.我授权检测机构对检测涉及的样本进行处理;</p>
<p>3.我自愿并同意将自己样本的检测结果用于本人检测报告单的制作;</p>
<p>4.我已知晓该技术服务仅用于评估二手烟接触情况，我自愿进行此检测并同意签字。</p>
<p>本检测只对送检样品负责。“晓飞检”对检测结果保留最终解释权，如有疑义请在收到结果后的3个工作日内与我们联系。</p>


<h2>知情同意书</h2>
<p>请您做基因检测前，仔细阅读以下内容:</p>
<h4>一、关于基因检测的信息</h4>
<p>1.检测目的是检测DNA变异，评估个体用药风险、患病风险或健康状况;</p>
<p>2.依据科学报道，并鉴于基因的复杂性及科研水平局限性，检测的基因并不一-定包含所有相关基因，基因检测并不能检测
所有突变位点并提供完整的遗传信息;同时，限于目前的临床及科研水平，并不是所有与疾病发生、诊断、治疗相关的基
因都被临床所证实;</p>
<p>3.该检测需要高质量的DNA,如果样本体积、质量或初始样本的其他条件不能满足检测要求，可能需要重新采集样本;</p>
<p>4.该检测服务仅用于评估个体用药风险程度、患病风险程度或健康状况，而非诊断;</p>
<p>5.本公司承诺对受检者所有信息进行保密，包括个人信息和检测结果信息;</p>
<p></p>6.满足下列要求后，本公司方可进行检测:</p>
<p>(1) 受检者已按要求填写检测申请单并已签署知情同意书;</p>
<p>(2) 采集样本严格按照标准流程进行操作。</p>
<p>7.在收到样本后-般会在7个工作日内完成检测并出具报告，特殊情况可能需要更长时间(如样本不符合标准需要重新采祥等)</p>
<p>8.在检测过程中及知悉检测结果后引起不同程度的精神压力和负担，本公司不承担任何责任;</p>
<p>9.受检者应对样本真实性(即样本是否属于受检者本人)负责。</p>
<h4>二、隐私声明</h4>
<p>我们承诺对受检者个人信息与遗传信息给予严格保护，检测结果和报告仅会通过受检者指定的方式告知。除非得到本人确
认，不会透露给第三方，但受检者知悉并许可我们在隐去受检者姓名等隐私信息后，在法律允许的框架内，在报告解读、
科研、教学及质量控制中合理使用受检者检测数据。</p>
<h4>三、申请人承诺与声明</h4>
<p>1.我已阅读了以上内容，并了解基因检测可能会带来的精神压力;</p>
<p>2.我授权检测机构对检测涉及的样本进行处理;</p>
<p>3.我有机会提问而且所有相关问题均已得到解答，并对解答无异议;</p>
<p>4.我自愿并同意将自己样本的基因检测结果用于本人基因检测报告单的制作;</p>
<p>5.我已知晓该技术服务仅用于评估个体用药风险、患病风险或健康状况，而非诊断,我自愿进行此基因检测。</p>`

export {
    str2
}